import React, {
	useState,
	useContext,
} from 'react';
import {
	map,
	reduce,
	capitalize,
} from 'lodash';
import PropTypes from 'prop-types';

import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import HistoryFilters from 'components/HistoryFilters.js';
import CmsContentList from 'components/data/CmsContentList.js';
import { ISODatePart, centsToDisplay } from "utils/FormatHelpers.js";
import PaymentHistoryTA from 'components/data/transit-account/PaymentHistoryTA.query.js';
import { getPayment } from 'components/data/transit-account/PaymentHistory.helpers.js';
import { dateFilterToStartEndDate ,
	NO_FILTERS,
	UNREGISTERED_HISTORY_DAYS,
	FILTER_TYPE_PURCHASE,
} from 'components/FiltersConstants.js';
import { getDefaultFilterDates } from 'utils/TripHistory.js';
import { isCreditDebitType } from 'server/api-types/WSPayment.js';
import PublicAppVars from "utils/PublicAppVars.js";
import TransitAccountIdContext from "context/TransitAccountIdContext.js";
import { types, ORDER_TYPE } from 'server/api-types/WSPaymentHistoryFactory.js';
import DataTable, { TABLE_PURCHASES } from 'components/DataTable.js';
import ServerDate, { getDateStringSync } from "components/ServerDate.js";
import PaymentHistoryChip from "components/card-icons/PaymentHistoryChip.js";
import DataTableZeroState, { TYPE_PURCHASES } from 'components/account/data-tables/DataTableZeroState.js';
import { getTransitModeIcon } from 'components/icons/TransitModes.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import {
	ALT_DATE_FORMAT,
	BALANCE_PRODUCT,

} from "utils/Constants.js";
import {
	wsPaymentHistoryIsRefund,
	getProductType,
} from 'utils/PurchaseHistory.js';
import { ReMapOpenTransitName } from 'components/account/card/activate-card/ActivateCard.js';

import * as tables from 'components/DataTable.module.css';
import { charlieCardCmskeys } from "../card/CharlieCardCmskeys.js";


const cms = {
	colDate: 'miscText.purchasehistory-table-date',
	colLocation: 'miscText.purchasehistory-table-location',
	colType: 'miscText.purchasehistory-table-type',
	colPurchaseProduct: 'miscText.purchasehistory-table-product',
	colAmount: 'miscText.purchasehistory-table-amount',
	colMethod: 'miscText.purchasehistory-table-payment-method',

	multipleProducts: 'miscText.purchasehistory-product-multi-product',
	startsOn: 'miscText.cardinfo-pass-starts',
	multipleRides: 'miscText.purchasehistory-product-multi-ride',

	online: 'miscText.purchasehistory-location-online',

	reload: 'miscText.purchasehistory-type-reload',
	autoload: 'miscText.purchasehistory-type-autoload',
	refund: 'miscText.purchasehistory-type-refund',
	balance: 'miscText.purchasehistory-product-balance',
	transfer: 'miscText.purchasehistory-type-transfer',

	noneDiscounts: 'miscText.purchasehistory-moreinfo-discounts-none',

	filterTitle: 'miscText.purchasehistory-filter-label',
	...charlieCardCmskeys,
};

const UNKNOWN_FACILITY = 'Unknown Facility'; // Origin if there are no trips and type is travel

export const ORDER_NUMBER = 'Order Number'; // paymentRefNbr
export const PURCHASED_ON = 'Purchased on'; // paymentHistoryDateTime
export const PURCHASED_AT = 'Purchased at'; // origin
export const PRODUCTS = 'Product(s)'; // productName
export const REFUND_GIVEN = 'Refund Given';

export const isMultiPurchase = (orderLineItems) => orderLineItems?.length > 1;
export const isMultiTrips = (trips) => trips?.length > 1;


export const PaymentMethodCell = ({ wsPaymentHistory }) => {
	const payment = getPayment(wsPaymentHistory);
	// TODO handle split in the future if nessesary
	// const isMultiPaymentCharge = getMultiPayment(wsPaymentHistory);
	// if (isMultiPaymentCharge) {
	// 	return <>
	// 		<SplitPay
	// 			aria-hidden="true"
	// 			data-qa="split-pay-icon"
	// 			className={tableStyle.splitPayIcon}
	// 		/>
	// 		<CmsContentRenderer.Span
	// 			contentKey={cms.splitPay}
	// 			fallbackValue={'HC: SplitPay'}
	// 		/>
	// 	</>;
	// }

	// handle missing data: https://reflexions.atlassian.net/browse/BO-71
	if (isCreditDebitType(payment.paymentType) && !payment?.maskedPAN && !payment?.maskedPan) {
		return <>&mdash;</>;
	}
	// transferred products
	if (wsPaymentHistory.orderType === ORDER_TYPE.transfer) {
		return <CmsContentRenderedInline contentKey={cms.transfer}
			fallbackValue="Transfer"
		/>;
	}

	return <PaymentHistoryChip
		iconClassName={tables.paymentIcon}
		{...{ wsPaymentHistoryInfo: payment }}
	/>;
};


export const TransitProductType = ({
	isRefund = false,

	// string(20)
	// (Required) The type to identify if it’s a order payment history of a
	// travel payment history. Valid values are:
	// • order
	// • travel
	type = types.travel,


	// WSOrderPaymentHistory extended from WSPaymentHistory when the type is order
	// has orderType
	orderType = "",
}) => {
	return getProductType({
		isRefund,
		type,
		orderType,
	});
};

//https://reflexions.atlassian.net/browse/MBTA-3035
// Following this logic we want to change the origin's name IF:
export const getPaymentHistoryOrigin = (wsPaymentHistory) =>
	wsPaymentHistory.origin === UNKNOWN_FACILITY
		? <CmsContentRenderedInline
			contentKey={cms.online}
			fallbackValue="Online"
		/>
		: wsPaymentHistory.origin;


export const TransitProductDescription = ({
	orderLineItems = null,
	trips = null,
}) => {
	const productName = orderLineItems?.[ 0 ].productName;

	if ((isMultiPurchase(orderLineItems) || isMultiTrips(trips))) {
		return <CmsContentRenderedInline
			contentKey={isMultiPurchase(orderLineItems) ? cms.multipleProducts : cms.multipleRides}
			fallbackValue={isMultiPurchase(orderLineItems) ? "Multiple products" : "Multiple rides"}
		/>;
	}

	if (orderLineItems?.length) {
		return <CmsContentList list={Object.values(cms)}>{({ cmsContent }) => productName === BALANCE_PRODUCT
			? <CmsContentRenderer.Span
				contentKey={cms.balance}
				fallbackValue="Balance"
			/>
			: ReMapOpenTransitName({
				tokenType: productName,
				cmsContent: cmsContent,
				tokenNickname: productName,
			})
		}</CmsContentList>;
	}

	return trips[ 0 ]?.travelMode
		? trips[ 0 ].travelMode
		: "";
};

const getDefaultState = (isUnregistered) => {
	if (isUnregistered) {
		const { startDateTime, endDateTime } = dateFilterToStartEndDate(UNREGISTERED_HISTORY_DAYS);
		return {
			...NO_FILTERS,
			dateFilterType: UNREGISTERED_HISTORY_DAYS,
			startDateTime: ISODatePart(startDateTime),
			endDateTime: ISODatePart(endDateTime),
		};
	} else {
		return {
			...NO_FILTERS,
			...getDefaultFilterDates({ isAlerts: false }),
		};
	}
};

const AmountCell = ({
	isRefund,
	value,
}) => {
	return (<>
		{isRefund
			? <div className={tables.amountRefund}>
				({centsToDisplay(Math.abs(value))})
			</div>
			: <div>
				{centsToDisplay(value)}
			</div>
		}
	</>);
};



export const columns = [
	{
		accessor: 'paymentHistoryDateTime',
		canSort: true,
		Header: <CmsContentRenderer.Span
			contentKey={cms.colDate}
			fallbackValue="Date"
		/>,
		Cell: ({ value }) => (
			<ServerDate
				classNames={tables.locale}
				dateISO={value}
				altFormat={ALT_DATE_FORMAT}
				options={{ month: 'short', day: '2-digit', year: 'numeric' }}
			/>
		),
	},
	{
		accessor: 'origin',
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.colLocation}
			fallbackValue="Location"
		/>,
		Cell: ({ row }) => getPaymentHistoryOrigin(row.original),
	},
	{
		accessor: 'type',
		disableSortBy: true,
		Header: <CmsContentRenderer.Span
			contentKey={cms.colType}
			fallbackValue="Type"
		/>,
		Cell: ({ row }) => {
			const wsPaymentHistory = row?.original;

			const isRefund = wsPaymentHistoryIsRefund(wsPaymentHistory);

			const {
				type,
				orderType,
			} = wsPaymentHistory;

			return <TransitProductType {...{
				isRefund,
				type,
				orderType,
			}}/>;
		},
	},
	{
		accessor: 'productName',
		disableSortBy: true,
		Header: <CmsContentRenderer.Span
			contentKey={cms.colPurchaseProduct}
			fallbackValue="Transit Product"
		/>,
		Cell: ({ row }) =>
			<TransitProductDescription {...{
				orderLineItems: row.original?.orderLineItems,
				trips: row.original?.trips,
			}}/>,
	},
	{
		accessor: 'amount',
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.colAmount}
			fallbackValue="Amount"
		/>,
		Cell: ({ row, value }) => {
			const wsPaymentHistory = row?.original;
			const isRefund = wsPaymentHistoryIsRefund(wsPaymentHistory);
			return <AmountCell {...{ isRefund, value }} />;
		},
	},
	{
		accessor: 'paymentDetails',
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.colMethod}
			fallbackValue="Payment Method"
		/>,
		Cell: ({ row }) => (
			<PaymentMethodCell
				{...{
					wsPaymentHistory: row.original,
				}}
			/>
		),
	},
];

// items order is important
const subRowHeaders = [
	ORDER_NUMBER,
	PURCHASED_ON,
	PURCHASED_AT,
	PRODUCTS,
	REFUND_GIVEN,
];

const getOrderTypeProductsData = ({
	orderLineItems,
}) => map(orderLineItems, orderLineItem => {
	const {
		productName,
		itemTotalAmount,
		productStartDate,
	} = orderLineItem;

	const formatProductStartDate = ({ productStartDate }) => {
		const date = getDateStringSync({
			dateISO: productStartDate,
			options: { month: 'short', day: '2-digit', year: 'numeric' }, altFormat: ALT_DATE_FORMAT,
		});

		return (
			<CmsContentRenderedInline
				contentKey={cms.startsOn}
				className={tables.startsOn}
				fallbackValue={`Valid starting on ${date}`}
				variables={{ date }}
			/>
		);
	};

	return {
		productName,
		productAmount: centsToDisplay(itemTotalAmount),
		startDate: productStartDate ? formatProductStartDate({ productStartDate }) : null,
	};
});

const getTravelTypeProductsData = ({
	trips,
	amount,
}) => {

	const getSummaryTripsLabel = () => (<>
		{trips?.length > 1
			? <CmsContentRenderedInline
				contentKey={cms.multipleRides}
				className={tables.startsOn}
				fallbackValue="Multiple trips"
			/>
			: trips?.length
				? <div>
					{trips[ 0 ]?.travelMode}
				</div>
				: null
		}
	</>);

	const productsSummary = {
		tripsSummaryLabel: getSummaryTripsLabel(),
		tripsSummaryAmount: centsToDisplay(amount),
	};

	const tripsLineItems = map(trips, tripLineItem => {
		const {
			startDateTime,
			travelMode,
			tripPaymentAmount,
		} = tripLineItem;

		const formatRideStartTime = ({ startDateTime }) => (<>
			<ServerDate
				classNames={tables.locale}
				dateISO={startDateTime}
				altFormat={ALT_DATE_FORMAT}
				options={{ month: 'short', day: '2-digit', year: 'numeric' }}
			/>
			<ServerDate
				classNames={tables.locale}
				dateISO={startDateTime}
				options={{
					hour: '2-digit',
					minute: '2-digit',
					hour12: true,
				}}
				altFormat={string => string.replace(/^0/, '')}
			/>
		</>);

		const renderModeIcon = () => (<>
			{getTransitModeIcon(capitalize(travelMode), { overrideClass: tables.transportIcon })}
		</>);

		return {
			startDate: startDateTime ? formatRideStartTime({ startDateTime }) : null,
			rideModeIcon: renderModeIcon(),
			tripAmount: centsToDisplay(tripPaymentAmount),
		};
	});
	tripsLineItems.unshift(productsSummary);
	return tripsLineItems;
};


export const getSubRows = (wsPaymentHistory) => {
	const isRefund = wsPaymentHistoryIsRefund(wsPaymentHistory);
	const {
		paymentHistoryDateTime,
		orderLineItems,
		orderId,
		amount,
		trips,
		type,
	} = wsPaymentHistory;

	const productsData = type === types.order
		? getOrderTypeProductsData({ orderLineItems })
		: getTravelTypeProductsData({ trips, amount });

	const datePayment = ({ paymentHistoryDateTime }) => (
		<ServerDate
			classNames={tables.locale}
			dateISO={paymentHistoryDateTime}
			altFormat={ALT_DATE_FORMAT}
			options={{ month: 'short', day: '2-digit', year: 'numeric' }}
		/>
	);

	const origin = getPaymentHistoryOrigin(wsPaymentHistory);

	// items order is important
	const subRowValues = [
		orderId,
		datePayment({ paymentHistoryDateTime }),
		origin,
		productsData,
	];

	if (isRefund) {
		subRowValues.push(centsToDisplay(amount));
	}

	const subRows = reduce(subRowHeaders, (acc, value, idx) => {
		if (subRowValues[ idx ]) {
			acc[ subRowHeaders[ idx ] ] = subRowValues[ idx ];
		}
		return acc;
	}, {});


	return subRows;
};

export const mapPaymentHistory = (lineItems) => map(lineItems, wsPaymentHistory => {
	return {
		...wsPaymentHistory,
		subRows: [ getSubRows(wsPaymentHistory) ],
		subRowHeaders: getSubRows(wsPaymentHistory),
	};
});



const PurchaseHistoryTable = ({
	showFilters = true,
	isUnregistered = false,
	transitAccountId = null,
	limit = PublicAppVars.TRIPS_HISTORY_PAGE_SIZE,
}) => {

	const contextTransitId = useContext(TransitAccountIdContext);
	const transit_account_id = transitAccountId ?? contextTransitId;

	const [ activePage, setActivePage ] = useState(0);
	const [ filtersApplied, setFiltersApplied ] = useState(getDefaultState(isUnregistered));

	return (<CmsContentList list={Object.values(cms)}>{() =>
		<section>
			<PaymentHistoryTA {...{
				subsystemAccountReference: transit_account_id,
				filtersApplied,
				offset: activePage * limit,
				limit,
			}}>
				{({ lineItems, totalCount }) => {
					const hasPurchases = Boolean(lineItems?.length);
					const mappedPurchases = mapPaymentHistory(lineItems);

					return (<>
						{(showFilters && !isUnregistered) &&
							<HistoryFilters
								title={cms.filterTitle}
								applyFilter={newFilters => {
									setFiltersApplied(newFilters);
									setActivePage(0);
								}}
								filters={filtersApplied}
								filterType={FILTER_TYPE_PURCHASE}
							/>
						}

						{/* Rendering indv rows instead of using DataTable on mobile bc they need to trigger a modal instead of expanding */}
						{hasPurchases
							? (<DataTable
								tableType={TABLE_PURCHASES}
								showPagination={totalCount > limit}
								itemsCountPerPage={limit}
								tableColumns={columns}
								tableData={mappedPurchases}
								transitAccountId={transit_account_id}
								{...{
									totalCount,
									activePage,
									setActivePage,
									isUnregistered,
									showFilters,
									filtersApplied,
								}}
								expandableRows
							/>)
							: <DataTableZeroState type={TYPE_PURCHASES} />
						}
					</>);
				}}
			</PaymentHistoryTA>
		</section>
	}</CmsContentList>);
};

PurchaseHistoryTable.propTypes = {
	showFilters: PropTypes.bool,
};

export default PurchaseHistoryTable;
