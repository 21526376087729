import React from "react";

import { getPaymentHistoryOrigin, TransitProductDescription } from "components/account/data-tables/PurchaseHistoryTable";
import { centsToDisplay } from "utils/FormatHelpers";
import ServerDate from "components/ServerDate";
import { ALT_DATE_FORMAT } from "utils/Constants";

import * as tableStyle from "components/DataTable.module.css";
import PaymentHistoryChip from "components/card-icons/PaymentHistoryChip";
import { isCreditDebitType } from "server/api-types/WSPayment";
import parseISO from "date-fns/parseISO";
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline";
import { ReMapOpenTransitName } from 'components/account/card/activate-card/ActivateCard.js';
import CmsContentList from 'components/data/CmsContentList.js';
import { values } from 'lodash';
import { charlieCardCmskeys } from "../../card/CharlieCardCmskeys.js";

const PRODUCT_DETAIL_VERBS = {
	start: 'Start',
	end: 'End',
};

const cms = {
	orderNumberHeader: 'miscText.purchasehistory-moreinfo-ordernum',
	orderPurchasedOnHeader: 'miscText.purchasehistory-moreinfo-when',
	orderPurchasedAtHeader: 'miscText.purchasehistory-moreinfo-where',
	orderProductHeader: 'miscText.triphistory-table-product',
	amountHeader: 'miscText.purchasehistory-table-amount',
	totalHeader: 'miscText.purchase-cart-total',
	dateHeader: 'miscText.purchasehistory-table-date',
	paymentMethodHeader: 'miscText.purchasehistory-table-payment-method',
	discountHeader: 'miscText.purchasehistory-moreinfo-discounts',
	detailProductHeader: 'miscText.purchasehistory-moreinfo-products',
	detailPriceHeader: 'miscText.purchase-cart-table-cost',
	detailFeeHeader: 'miscText.purchase-receipt-order-details-fee',
	detailItemTotalHeader: 'miscText.purchase-receipt-order-details-itemtotal',
	paymentAuthorizationHeader: 'miscText.purchase-receipt-payment-details-authorization',
	productDetails: "miscText.purchase-receipt-order-details-product-details",
};

export const orderSummaryColumns = [
	{
		accessor: 'orderId', // WSPaymentHistory.paymentRefNbr
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.orderNumberHeader}
			fallbackValue="Order Number"
		/>,
		Cell: ({ value }) => <>{value}</>,
	},
	{
		accessor: 'paymentHistoryDateTime', // WSPaymentHistory.paymentHistoryDateTime
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.orderPurchasedOnHeader}
			fallbackValue="Purchased On"
		/>,
		Cell: ({ value }) => (
			value ? <ServerDate
				classNames={tableStyle.locale}
				dateISO={value}
				options={{ month: 'short', day: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }}
			/> : <>&mdash;</>
		),
	},
	{
		accessor: 'origin', // WSPaymentHistory.origin
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.orderPurchasedAtHeader}
			fallbackValue="Purchased At"
		/>,
		Cell: ({ row }) => getPaymentHistoryOrigin(row.original),
	},
	{
		accessor: 'productName', // WSOrderPaymentHistory.orderLineItems[0].productName | WSTravelPaymentHistory.trips[0].travelMode
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.orderProductHeader}
			fallbackValue="Transit Product"
		/>,
		Cell: ({ row }) => (
			<TransitProductDescription {...{
				orderLineItems: row.original?.orderLineItems,
				trips: row.original?.trips,
			}} />
		),
	},
	{
		accessor: 'amount', // WSPaymentHistory.amount
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.totalHeader}
			fallbackValue="Total"
		/>,
		Cell: ({ value }) => <>{centsToDisplay(value)}</>,
	},
];

export const orderDetailsColumns = (isOrderType) => [
	{
		accessor: isOrderType
			? 'productName' // WSPaymentHistoryOrderLineItem.productName
			: 'travelMode', // WSTravelPaymentHistoryDetail.travelMode
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.detailProductHeader}
			fallbackValue="Product"
		/>,
		Cell: ({ value }) => <CmsContentList list={values(charlieCardCmskeys)}>{({ cmsContent }) => (
			<>{ReMapOpenTransitName({
				tokenType: value,
				cmsContent,
				tokenNickname: value,
			})}</>
		)}</CmsContentList>,
	},
	{
		accessor: 'quantity', // manually counted and set
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.amountHeader}
			fallbackValue="Quantity"
		/>,
		Cell: ({ value }) => <>{value}</>,
	},
	{
		accessor: isOrderType
			? 'productStartDate' // WSPaymentHistoryOrderLineItem.productStartDate
			: 'startDateTime',   // WSTravelPaymentHistoryDetail.startDateTime
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.productDetails}
			fallbackValue="Product Details"
		/>,
		Cell: ({ row }) => {
			if (isOrderType) {
				const { productStartDate } = row.original; // WSPaymentHistoryOrderLineItem.productStartDate
				if (!productStartDate) {
					return <>&mdash;</>;
				}
				return <ProductDetailsCell
					dateISO={productStartDate}
					verb={PRODUCT_DETAIL_VERBS.start}
				/>;
			} else {
				const {
					startDateTime, // WSTravelPaymentHistoryDetail.startDateTime
					endDateTime, // WSTravelPaymentHistoryDetail.endDateTime
				} = row.original;
				return <ProductDetailsCell
					dateISO={startDateTime ?? endDateTime}
					verb={startDateTime ? PRODUCT_DETAIL_VERBS.start : PRODUCT_DETAIL_VERBS.end}
				/>;
			}
		},
	},
	{
		accessor: isOrderType
			? 'itemTotalAmount'    // WSPaymentHistoryOrderLineItem.itemTotalAmount
			: 'tripPaymentAmount', // WSTravelPaymentHistoryDetail.tripPaymentAmount
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.detailPriceHeader}
			fallbackValue="Price"
		/>,
		Cell: ({ value }) => <>{centsToDisplay(value)}</>,
	},
	{
		accessor: 'discountAmount', // WSTravelPaymentHistoryDetail.discountAmount
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.discountHeader}
			fallbackValue="Discount"
		/>,
		Cell: ({ value }) => value ? <>{centsToDisplay(value)}</> : <>&mdash;</>,
	},
	{
		accessor: 'feeAmount', // WSPaymentHistoryOrderLineItem.feeAmount
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.detailFeeHeader}
			fallbackValue="Fee"
		/>,
		Cell: ({ value }) => value ? <>{centsToDisplay(value)}</> : <>&mdash;</>,
	},
	{
		accessor: 'itemTotal', // manually calculated
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.detailItemTotalHeader}
			fallbackValue="Item Total"
		/>,
		Cell: ({ row }) => <>{isOrderType
			// TODO: Sanity check these calculations
			? centsToDisplay((row.original.itemTotalAmount + row.original.feeAmount) * row.original.quantity)
			: centsToDisplay((row.original.tripPaymentAmount - row.original.discountAmount) * row.original.quantity)
		}</>,
	},
];


export const paymentDetailsColumns = [
	{
		accessor: 'paymentMethod', // Icon and text derived from WSPaymentHistoryInfo.paymentType
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.paymentMethodHeader}
			fallbackValue="Payment Method"
		/>,
		Cell: ({ row }) => {
			const payment = row.original;
			if (isCreditDebitType(payment.paymentType) && !payment?.maskedPAN && !payment?.maskedPan) {
				return <>&mdash;</>;
			}
			return <PaymentHistoryChip
				iconClassName={tableStyle.paymentIcon}
				{...{ wsPaymentHistoryInfo: payment }}
			/>;
		},
	},
	{
		accessor: 'paymentDateTime', // WSPaymentHistoryInfo.paymentDateTime
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.dateHeader}
			fallbackValue="Date"
		/>,
		Cell: ({ value }) => (
			value ? <ServerDate
				classNames={tableStyle.locale}
				dateISO={value}
				altFormat={ALT_DATE_FORMAT}
				options={{ month: 'short', day: '2-digit', year: 'numeric' }}
			/> : <>&mdash;</>
		),
	},
	{
		accessor: 'paymentAmount', // WSPaymentHistoryInfo.paymentAmount
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.amountHeader}
			fallbackValue="Amount"
		/>,
		Cell: ({ value }) => value ? <>{centsToDisplay(value)}</> : <>&mdash;</>,
	},
	{
		accessor: 'authRefNbr', // WSPaymentHistoryInfo.authRefNbr
		disableSortBy: true,
		Header: <CmsContentRenderedInline
			contentKey={cms.paymentAuthorizationHeader}
			fallbackValue="Authorization"
		/>,
		Cell: ({ value }) => value ? <>{value}</> : <>&mdash;</>,
	},
];

/**
 * @param {string} dateISO ISO date string
 * @param {string} verb Start | End
 */
const ProductDetailsCell = ({ dateISO, verb }) => {
	const todayMilli = new Date().getTime();
	const valueMilli = parseISO(dateISO).getTime();
	const dateTimeIsInPast = valueMilli < todayMilli;
	return <>
		<span>{dateTimeIsInPast ? `${verb}ed` : `${verb}s`} on </span>
		<ServerDate
			classNames={tableStyle.locale}
			dateISO={dateISO}
			options={{ month: 'short', day: '2-digit' }}
		/>
	</>;
};
