import React from 'react';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Banner, { TYPE_ERROR } from 'components/Banner.js';
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';

import Button, { buttonTypeStylePlain } from 'components/Button.js';
import * as style from './RegisterForAccount.module.css';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline';


const cms = {
	title: 'miscText["dashboard-banner-payment-expired.title"]',
	description: 'miscText["dashboard-banner-payment-expired.description"]',
	link: 'miscText["dashboard-banner-payment-expired.button"]',
};


const ExpiredPaymentMethodBanner = ({ expiredFundingSource }) => {
	const nickname = expiredFundingSource.nickname
		? expiredFundingSource.nickname
		: `${expiredFundingSource.creditCard.creditCardType} ...${expiredFundingSource.creditCard.maskedPan}`;

	return <CmsContentList list={Object.values(cms)}>{() => (
		<Banner
			type={TYPE_ERROR}
			title={<CmsContentRenderedInline
				contentKey={cms.title}
				fallbackValue={`${nickname} has Expired`}
				variables={{ nickname }}
			/>}
			body={<CmsContentRenderer.P
				contentKey={cms.description}
				fallbackValue="Please update your primary & backup payment methods to ensure payments and autoloads continue to work as expected."
			/>}
			link={
				<Button
					to={getPathByRoute(routeKeys.AccountPaymentMethods)}
					typeStyle={buttonTypeStylePlain}
					additionalClassNames={style.registerButtonOverride}
				>
					<CmsContentRenderer.Span contentKey={cms.link} fallbackValue="Manage saved payments" />
				</Button>
			}
		/>
	)}</CmsContentList>;
};

export default ExpiredPaymentMethodBanner;
