import React from 'react';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Banner, { TYPE_WARNING } from 'components/Banner.js';
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';

import Button, { buttonTypeStylePlain } from 'components/Button.js';
import * as style from './RegisterForAccount.module.css';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';
import { shortYear } from 'utils/FormatHelpers.js';


const cms = {
	title: 'miscText["card-overview-banner-charlie-expiring.title"]',
	description: 'miscText["card-overview-banner-charlie-expiring.description"]',
	link: 'miscText["card-overview-banner-charlie-expiring.button"]',
};


const ExpiringClosedLoop = ({ primaryTokenInfo, transit_account_id }) => {
	const expirationDate = new Date(primaryTokenInfo.cardExpiryDate);

	const month = expirationDate.getMonth() + 1;
	const year = shortYear(expirationDate.getFullYear());

	// Format to MM/YY
	const formattedExpiration =`${month}/${year}`;

	return <CmsContentList list={Object.values(cms)}>{() => (
		<Banner
			type={TYPE_WARNING}
			title={<CmsContentRenderer.Span
				contentKey={cms.title}
				fallbackValue={"Charlie Card Expiring Soon"}

			/>}
			body={<CmsContentRenderedInline
				contentKey={cms.description}
				fallbackValue={`This Charlie Card is valid until ${formattedExpiration}`}
				variables={{ date: formattedExpiration }}
			/>}
			link={
				<Button
					to={getPathByRoute(routeKeys.BlockOrReplaceFlow, { transit_account_id })}
					typeStyle={buttonTypeStylePlain}
					additionalClassNames={style.registerButtonOverride}
				>
					<CmsContentRenderer.Span contentKey={cms.link} fallbackValue="Order Replacement" />
				</Button>
			}
		/>
	)}</CmsContentList>;
};

export default ExpiringClosedLoop;
