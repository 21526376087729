import React from 'react';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Banner, { TYPE_WARNING } from 'components/Banner.js';
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';

import Button, { buttonTypeStylePlain } from 'components/Button.js';
import * as style from './RegisterForAccount.module.css';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline';


const cms = {
	title: 'miscText["dashboard-banner-payment-expiring.title"]',
	description: 'miscText["dashboard-banner-payment-expiring.description"]',
	link: 'miscText["dashboard-banner-payment-expiring.button"]',
};


const ExpiringPaymentMethodBanner = ({ expiringFundingSource }) => {
	const expiration = expiringFundingSource.creditCard.cardExpiryMMYY;
	const nickname = expiringFundingSource.nickname
		? expiringFundingSource.nickname
		: `${expiringFundingSource.creditCard.creditCardType} ...${expiringFundingSource.creditCard.maskedPan}`;

	// Format to MM/YY
	let formattedExpiration = expiration.slice(0, 2) + "/" + expiration.slice(2);
	// Remove proceeding 0's
	formattedExpiration = formattedExpiration.replace(/^0+/, '');

	return <CmsContentList list={Object.values(cms)}>{() => (
		<Banner
			type={TYPE_WARNING}
			title={<CmsContentRenderedInline
				contentKey={cms.title}
				fallbackValue={`${nickname} is Valid Until ${formattedExpiration}`}
				variables={{ nickname, date: formattedExpiration }}
			/>}
			body={<CmsContentRenderer.P
				contentKey={cms.description}
				fallbackValue="Please review your primary & backup payment methods to ensure payments and autoloads continue to work as expected."
			/>}
			link={
				<Button
					to={getPathByRoute(routeKeys.AccountPaymentMethods)}
					typeStyle={buttonTypeStylePlain}
					additionalClassNames={style.registerButtonOverride}
				>
					<CmsContentRenderer.Span contentKey={cms.link} fallbackValue="Manage saved payments" />
				</Button>
			}
		/>
	)}</CmsContentList>;
};

export default ExpiringPaymentMethodBanner;
