import React, { useState, useEffect, useMemo } from "react";
import cx from "classnames";
import {
	Redirect,
} from "react-router-dom";

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import HelmetOGTags from 'layouts/HelmetOGTags.js';
import { useCookies } from "react-cookie";
import CmsContentList from 'components/data/CmsContentList.js';
import { isActiveOrNegative, isNegativeBalance } from "utils/transit-account-utils/StatusUtils.js";
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Toast from "components/Toast.js";
import { useTransitAccount } from "components/data/transit-account/TransitAccount.js";
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import { useEmvCanReload } from "components/data/transit-account/EMV.helpers.js";

import {
	useTransitAccountIdContext,
} from "context/TransitAccountIdContext.js";
import PublicAppVars from "utils/PublicAppVars.js";

import CardLayout, { getCardLayoutBreadcrumbs } from "layouts/CardLayout.js";
import TripHistoryTable from "components/account/data-tables/TripHistoryTable.js";
import CardSummary from 'components/account/CardSummary.js';
import Button, { buttonTypeStylePlain } from "components/Button.js";
import MyGroups from "components/account/panels/MyGroups.js";
import Tooltip from "components/Tooltip.js";

import CmsContentRendered from "components/data/CmsContentRendered.js";
import {
	POST_SIGN_IN_UPGRADE_CARD_TRANSIT_ACOUNT_ID,
	POST_SIGN_IN_REPLACE_CARD_TRANSIT_ACOUNT_ID,
	postSignInCookies,
	cookieOptions,
} from "components/data/session-user/PostSigninGoToPath.js";
import ActivateCard from 'components/account/card/activate-card/ActivateCard.js';

import * as admin from "layouts/Admin.module.css";
import * as overview from "./Overview.module.css";
import * as buttonStyles from "components/Button.module.css";
import * as tooltipStyle from 'components/Tooltip.module.css';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline";
import { useBreadcrumbCallback } from "context/BreadcrumbProvider";
import UpgradeCard from "components/banners/UpgradeCard.js";
import ReplaceCard from "components/banners/ReplaceCard.js";
import { getSupportsTransferPurses } from "context/PurseBalanceContext.js";
import { ComponentLoading } from "components/icons/LoadingIcon.js";
import ExpiredClosedLoop from "components/banners/ExpiredClosedLoop.js";
import ExpiringClosedLoop from "components/banners/ExpiringClosedLoop.js";
import { isCharlieCard } from "utils/transit-account-utils/FindCardType.js";
import { getMasterTokenInfo } from "components/manage-cards/TokenHelpers.js";



const cms = {
	header: 'miscText.cardinfo-header',
	transferProducts: 'miscText.card-overview-transfer-products-cta',
	transferProductsToolTip: 'miscHtml.card-overview-transfer-products-tooltip',
	recentRides: 'miscText.card-overview-triphistory-header',
	viewAll: 'miscText.card-overview-triphistory-viewfull',
	loadPassesOrBalance: 'miscText.cardmenu-purchase',
	noTransferProducts: 'miscText.card-overview-transfer-products-no-valid-products',
	inactiveAccount: 'miscText.card-overview-transfer-products-inactive',
};


const TransferLink = ({
	cmsContent,
	transit_account_id,
	transitAccountQ,
}) => {
	const { setToast, removeToast } = useGlobalToastsContext();
	const [ redirect, setRedirect ] = useState(null);

	// Either redirect to the transfer page or throw a toast message
	const handleTransferOnClick = () => {

		const elidgableTransferPurses = getSupportsTransferPurses(transitAccountQ?.purses);

		// Logic is slightly different from the logic to display the button.
		// Here we will implement logic to check whether the user can enter the flow.
		const canTransferProducts =
		transitAccountQ.passes?.length > 0	||
			(elidgableTransferPurses?.length > 0
			&& elidgableTransferPurses?.some(purse => purse.balance >= PublicAppVars.TRANSFER_MIN_AMOUNT));

		if (!canTransferProducts) {
			setToast(<Toast
				type="error"
				title={<CmsContentRendered.Span
					contentKey={cms.noTransferProducts}
					fallbackValue={"There are no valid products to transfer at this time"}
				/>}
				onClosed={removeToast}
			/>);
			return;
		}

		if (isNegativeBalance(transitAccountQ)) {
			setToast(<Toast
				type="error"
				title={<CmsContentRendered.Span
					contentKey={cms.inactiveAccount}
					fallbackValue="Your card status must be active in order to initiate a transfer"
				/>}
				onClosed={removeToast}
			/>);
			return;
		}

		setRedirect(<Redirect
			push
			to={{ pathname: getPathByRoute(routeKeys.AccountCardTransferProducts, { transit_account_id }) }}
		/>);
	};

	if (redirect) {
		return redirect;
	}

	return (
		<div className={overview.transferLinkWrapper}>
			<Button
				type="button"
				typeStyle={buttonTypeStylePlain}
				onClick={handleTransferOnClick}
				additionalClassNames={cx(admin.headerAction, buttonStyles.link)}
			>
				<CmsContentRendered.Span
					contentKey={cms.transferProducts}
					fallbackValue="Transfer Products"
					data-qa="TransferProductsCTA"
				/>
			</Button>
			<Tooltip
				tooltipId={'transferProductsLinkTooltip'}
				ariaLabel={cmsContent[ cms.transferProducts ] || 'Transfer Products'}
				ariaLabelPanel={cmsContent[ cms.transferProductsToolTip ] || 'Transfer balance or unused passes to another card you manage. <a href ="https://www.mbta.com/charlie/transfer">Restrictions apply</a>.'}
			>
				<CmsContentRendered.Div
					rawHtml
					className={tooltipStyle.text}
					contentKey={cms.transferProductsToolTip}
					fallbackValue='<p>Transfer balance or unused passes to another card you manage. <a href ="https://www.mbta.com/charlie/transfer">Restrictions apply</a>.</p>'
					data-qa="TransferProductsToolTip"
				/>
			</Tooltip>
		</div>
	);
};

export const getCardOverviewBreadcrumbs = () => [
	...getCardLayoutBreadcrumbs(),
	<CmsContentRenderedInline
		key={cms.header}
		contentKey={cms.header}
		fallbackValue="Overview"
	/>,
];


const useIsClosedLoopExpiry = transitAccountQ => {
	let isClosedLoopExpired = false;
	let isClosedLoopExpiring = false;

	const today = useMemo(() => new Date(), []);

	// Bail out early if the TA is not closed loop
	if (!transitAccountQ?.tokens || !isCharlieCard(transitAccountQ)) {
		return {
			isClosedLoopExpired,
			isClosedLoopExpiring,
		};
	}

	const primaryTokenInfo = getMasterTokenInfo(transitAccountQ?.tokens);

	const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
	const tokenInfoExpiration = new Date(primaryTokenInfo.cardExpiryDate);

	// If the card is expired no need to do any further calculations
	if (tokenInfoExpiration.getTime() < today.getTime()) {
		isClosedLoopExpired = true;
		isClosedLoopExpiring = false;
	}
	else {
		const daysTilExpired = Math.round(Math.abs((tokenInfoExpiration - today) / oneDay));
		// We only want the Closed Loop cards between the window of 0 days and Nth days.
		isClosedLoopExpiring = daysTilExpired > 0 && daysTilExpired <= PublicAppVars.EXPIRING_CLOSED_LOOP_DAY_COUNT;
		isClosedLoopExpired = daysTilExpired <= 0;
	}

	return {
		isClosedLoopExpired,
		isClosedLoopExpiring,
	};

};

const Overview = () => {
	useBreadcrumbCallback(getCardOverviewBreadcrumbs);
	const transit_account_id = useTransitAccountIdContext();

	const emvCanReload = useEmvCanReload({ subsystemAccountReference: transit_account_id });

	const {
		data: transitAccountQdata,
		loading: transitAccountQLoading,
	} = useTransitAccount({ subsystemAccountReference: transit_account_id });

	const transitAccountQ = transitAccountQdata?.transitAccountQ;

	const [ cookies, _, removeCookie ] = useCookies(postSignInCookies);

	// TA for specific banners
	const postSignInUpgradeTA = cookies[ POST_SIGN_IN_UPGRADE_CARD_TRANSIT_ACOUNT_ID ];
	const postSignInReplaceTA = cookies[ POST_SIGN_IN_REPLACE_CARD_TRANSIT_ACOUNT_ID ];

	// State
	const [ isUpgradeCardBanner, setIsUpgradeCardBanner ] = useState(false);
	const [ isReplaceCardBanner, setIsReplaceCardBanner ] = useState(false);

	// Check if we have any Cookies that need to removed after saving the state locally
	useEffect(() => {
		if (postSignInUpgradeTA && transit_account_id === postSignInUpgradeTA && PublicAppVars.ENABLE_UPGRADE_CARD) {
			setIsUpgradeCardBanner(true);
			removeCookie(POST_SIGN_IN_UPGRADE_CARD_TRANSIT_ACOUNT_ID, cookieOptions);
		}
	}, [ postSignInUpgradeTA, transit_account_id, removeCookie ]);

	useEffect(() => {
		if (postSignInReplaceTA && transit_account_id === postSignInReplaceTA) {
			setIsReplaceCardBanner(true);
			removeCookie(POST_SIGN_IN_REPLACE_CARD_TRANSIT_ACOUNT_ID, cookieOptions);
		}
	}, [ postSignInReplaceTA, transit_account_id, removeCookie ]);

	// https://reflexions.atlassian.net/browse/MBTA-1685
	// TransitAccountQ has fully loaded AND
	// emvCanReload === true OR (balance > 0 || numPasses > 0)
	const canTransferProducts = !transitAccountQLoading
		&& (emvCanReload
			|| transitAccountQ?.passes?.length > 0
			|| transitAccountQ?.purses?.some(purse => purse.balance > 0)
		);

	const canReload = useMemo(() => {
		if (!transitAccountQ) {
			return false;
		}

		return emvCanReload && isActiveOrNegative(transitAccountQ);

	}, [ transitAccountQ, emvCanReload ]);

	const { isClosedLoopExpired, isClosedLoopExpiring } = useIsClosedLoopExpiry(transitAccountQ);

	if (transitAccountQLoading) {
		return <ComponentLoading />;
	}

	const primaryTokenInfo = getMasterTokenInfo(transitAccountQ?.tokens);

	return (
		<CmsContentList list={Object.values(cms)}>{({ cmsContent }) => (
			<>
				{isUpgradeCardBanner && <UpgradeCard {...{ transit_account_id }} />}
				{isReplaceCardBanner && <ReplaceCard {...{ transit_account_id }} />}

				{isClosedLoopExpired && <ExpiredClosedLoop {...{ transit_account_id }}/>}
				{isClosedLoopExpiring && <ExpiringClosedLoop {...{ transit_account_id, primaryTokenInfo }} />}

				<CardLayout
					title={(cmsContent[ cms.header ] || "Card Overview")}
					showMissedTaps={true}
				>
					<ActivateCard />
					<HelmetOGTags title={`${transit_account_id} Details`} />

					<CardSummary />

					{canReload &&
						<div className={overview.mobileButtons}>
							<Button isPrimary
								to={{
									pathname: getPathByRoute(routeKeys.AccountPurchaseProduct, { transit_account_id }),
									state: { purchase: true },
								}}
								additionalClassNames={overview.fullWidthButton}
							>
								<CmsContentRenderer.Span
									contentKey={cms.loadPassesOrBalance}
									fallbackValue="Add money or passes"
								/>
							</Button>
						</div>

					}
					{canTransferProducts
						? <TransferLink {...{ cmsContent, transit_account_id, transitAccountQ }} />
						: null
					}

					{PublicAppVars.ENABLE_TOKEN_GROUPS
						? <MyGroups />
						: null
					}

					<section className={overview.rides}>
						<div className={admin.tripsHeader}>
							<h2 className={admin.tripsPageTitle}>
								<CmsContentRenderer.Span
									contentKey={cms.recentRides}
									fallbackValue="Recent Trips"
									data-qa="AdminPageTitle"
								/>
							</h2>
							<Button to={getPathByRoute(routeKeys.AccountCardRideHistory, { transit_account_id })}
								typeStyle={buttonTypeStylePlain}
								additionalClassNames={cx(admin.headerAction, overview.viewTripHistory)}
							>
								<CmsContentRenderer.Span
									contentKey={cms.viewAll}
									fallbackValue="View Full Ride History"
								/>
							</Button>
						</div>

						<TripHistoryTable
							showFilters={false}
							canPrint={false}
							limit={PublicAppVars.CARD_OVERVIEW_RECENT_TRIPS_LIMIT}
						/>
					</section>
				</CardLayout>
			</>
		)}</CmsContentList>
	);
};

export default Overview;
