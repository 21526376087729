import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CmsContentList from 'components/data/CmsContentList.js';

import Modal from 'components/Modal.js';
import { PurchaseMainStats } from 'components/account/data-tables/PurchaseHistoryMobileRow.js';
import {
	SubRowsHeaders,
	SubRowsCells,
} from 'components/DataTable.js';
import TableRowActions from 'components/account/data-tables/TableRowActions.js';
import { types } from 'server/api-types/WSPaymentHistoryFactory.js';

import * as tables from 'components/DataTable.module.css';
import * as rowStyle from 'components/account/data-tables/PurchaseHistoryMobileRow.module.css';
import { getSubRows } from "components/account/data-tables/PurchaseHistoryTable.js";




const cms = {
	print: 'miscText.purchasehistory-printsingle',
	printSingle: 'miscText.purchasehistory-printsingle-aria',
};

const PurchaseHistoryDetails = ({
	purchase,
	onModalClose,
	transitAccountId,
}) => {
	const {
		type,
		original,
	} = purchase;

	const isTravelMode = type === types.travel;

	const subRowHeaders = getSubRows(original ?? purchase);
	const subRows = [ getSubRows(original ?? purchase) ];

	return (<CmsContentList list={Object.values(cms)}>{({ cmsContent }) => (
		<Modal
			title="Purchase Details"
			overrideHeaderClass={tables.modalHeader}
			overrideCloseClass={tables.modalHeader}
		>
			<div className={cx(rowStyle.container, rowStyle.detailView)}
				role="row"
			>
				<PurchaseMainStats {...{ purchase }} />
			</div>

			<div>
				<dl className={tables.purchaseDetails}>
					<SubRowsHeaders {...{ row: purchase, subRowHeaders, subRows, isPurchaseTable: true }} />
					<SubRowsCells {...{ row: purchase, subRows, isTravelMode, isPurchaseTable: true }} />
				</dl>
			</div>
			<div>
				<TableRowActions {...{
					isPurchaseTable: true,
					clonedRow: purchase,
					onModalClose,
					transitAccountId,
				}} />
			</div>
		</Modal>
	)}</CmsContentList>);
};

PurchaseHistoryDetails.propTypes = {
	purchase: PropTypes.object,
};

export default PurchaseHistoryDetails;
