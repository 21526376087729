import React, { useState } from 'react';
import cx from "classnames";

import CmsContentList from 'components/data/CmsContentList.js';
import CmsObject from 'components/data/CmsObject.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import Container from 'components/Container.js';
import Button, { Ghost } from 'components/Button.js';
import { useLoginStage } from 'components/data/session-user/LoggingIn';

import * as style from "./Hero.module.css";
import { getPathByRoute } from 'App.js';
import loginStages from 'components/data/session-user/LoginStages.js';
import Toast, { TYPE_ERROR } from 'components/Toast.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';
import routeKeys from 'CustomerRouteKeys.js';
import { Redirect } from 'react-router-dom';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';


const cms = {
	title: 'miscText.landing-hero-title',
	text: 'miscText.landing-hero-text',
	btnText: 'miscText.landing-hero-button-text',
	btnUrl: 'miscText.landing-hero-button-url',
	ctaText: 'miscText.landing-hero-cta-text',
	ctaUrl: 'miscText.landing-hero-cta-url',
	manageUnregisteredCardWhileLoggedIn: 'miscText.landing-toplink-guest-login-registered-warning',
};

const Hero = () => {
	const { setToast, removeToast } = useGlobalToastsContext();
	const { loginStage } = useLoginStage();
	const [ redirect, setRedirect ] = useState();

	// If the user is logged in we want to go to the Account Dashboard and not display the toast message for a new card
	const userIsLoggedIn = loginStage === loginStages.loggedIn;


	if (redirect) {
		return redirect;
	}


	return <CmsContentList list={Object.values(cms)}>{() =>
		<div className={cx(style.wrapper)}>
			<CmsObject contentKey="heroes">{({ data }) => {
				const hero = data.cmsObject.obj[ "home-page-1" ];

				return hero?.background ? (
					<>
						<img src={hero.background} className={style.bgImage} aria-hidden={true} />
						<Container overrideClass={style.container}>
							<div className={style.content}>
								<h1
									className={style.title}
									data-qa="HomeHeroTitle"
								>
									{hero.heading}
								</h1>

								<CmsContentRenderer.H2
									rawHtml
									content={hero.text}
									className={style.subtitle}
									data-qa="HomeHeroTitle"
								/>

								<div>
									<Button
										typeStyle={Ghost}
										to={hero.button_url}
										additionalClassNames={style.btn}
										data-qa="HomeHeroAction"
									>
										<span>{hero.button_text}</span>
									</Button>

									<Button
										typeStyle={Ghost}
										onClick={() => userIsLoggedIn
											? setToast(
												<Toast
													type={TYPE_ERROR}
													onClosed={ removeToast }
													timeout={0}
													title={<CmsContentRenderedInline contentKey={cms.manageUnregisteredCardWhileLoggedIn}
														fallbackValue="Please log out in order to manage an unregistered card"
													/>}
												/>
											)
											: setRedirect(<Redirect push to={{
												pathname: getPathByRoute(routeKeys.ViewTransactionHistory),
											}} />)
										}
										additionalClassNames={style.cta}
									>
										<span>{hero.cta_text}</span>
									</Button>
								</div>
							</div>
						</Container>
					</>) : null;
			}}</CmsObject>
		</div>
	}</CmsContentList>;
};

export default Hero;
