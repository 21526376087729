/**
 * This displays a single row in the PurchaseHistoryTable, but only on
 * mobile (<768px) devices as the rows need to open a modal dialog,
 * which only happens on that table and not Trips.
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRendered from "components/data/CmsContentRendered.js";
import ServerDate from 'components/ServerDate.js';
import { ALT_DATE_FORMAT } from 'utils/Constants.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';
import { useModalContext } from 'context/ModalProvider.js';
import PreventDefault from 'utils/PreventDefault.js';
import PurchaseHistoryDetails from 'components/modals/PurchaseHistoryDetails.js';
import { wsPaymentHistoryIsRefund } from 'components/data/transit-account/PaymentHistory.helpers.js';

import {
	TransitProductType,
	TransitProductDescription,
	PaymentMethodCell,
	getPaymentHistoryOrigin,
} from './PurchaseHistoryTable.js';
import { Chevron } from 'components/icons/UI.js';

import * as tables from 'components/DataTable.module.css';
import * as rowStyle from './PurchaseHistoryMobileRow.module.css';



const cms = {
	location: 'miscText.purchasehistory-table-location',
	amount: 'miscText.purchasehistory-table-amount',
	payment: 'miscText.purchasehistory-table-payment-method',
};


/**
 * Main display of purchase stats, used in both the regular row and
 * the top of the details modal
 */
export const PurchaseMainStats = ({ purchase }) => {
	const isRefund = wsPaymentHistoryIsRefund(purchase);
	const {
		type,
		orderType,
	} = purchase;

	const origin = getPaymentHistoryOrigin(purchase);

	return (<>
		<div className={rowStyle.productName}>
			<TransitProductDescription {...{
				orderLineItems: purchase?.orderLineItems,
				trips: purchase?.trips,
			}} />
		</div>

		<div className={rowStyle.purchaseDate}>
			<ServerDate
				classNames={cx(tables.locale, rowStyle.fadedText)}
				dateISO={purchase.paymentHistoryDateTime}
				altFormat={ALT_DATE_FORMAT}
				options={{ month: 'short', day: '2-digit', year: 'numeric' }}
			/>

			<span className={cx(rowStyle.purchaseType, rowStyle.fadedText)}>
				<TransitProductType {...{
					isRefund,
					type,
					orderType,
				}} />
			</span>
		</div>


		{/* Bottom row of cell, 3 columns */}
		<div className={rowStyle.location}>
			<CmsContentRendered.P
				contentKey={cms.location}
				fallbackValue="Location"
				className={rowStyle.fadedText}
			/>

			<p className={rowStyle.purchaseValue}>
				{origin}
			</p>
		</div>

		<div className={rowStyle.amount}>
			<CmsContentRendered.P
				contentKey={cms.amount}
				fallbackValue="Amount"
				className={rowStyle.fadedText}
			/>

			<p className={rowStyle.purchaseValue}>
				{centsToDisplay(purchase.amount)}
			</p>
		</div>

		<div className={rowStyle.payment}>
			<CmsContentRendered.P
				contentKey={cms.payment}
				fallbackValue="Payment"
				className={rowStyle.fadedText}
			/>

			<div className={rowStyle.purchaseValue}>
				<PaymentMethodCell
					{...{
						wsPaymentHistory: purchase,
					}}
				/>
			</div>
		</div>
	</>);
};


const PurchaseHistoryMobileRow = ({
	purchase,
	transitAccountId,
}) => {

	const { setModal } = useModalContext();
	const onModalClose = () => {
		setModal(null);
	};

	return (<div className={cx(tables.rowWrapper, rowStyle.container)}
		onClick={PreventDefault(() => setModal(<PurchaseHistoryDetails {...{ purchase, onModalClose, transitAccountId }} />))}
		role="row"
	>
		<PurchaseMainStats {...{ purchase }} />

		<Chevron overrideClass={rowStyle.chevron} />
	</div>);
};

PurchaseHistoryMobileRow.propTypes = {
	purchase: PropTypes.object.isRequired,
};

export default PurchaseHistoryMobileRow;
