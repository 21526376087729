import { useMemo } from 'react';
import {
	FUNDING_SOURCES_GET_QUERY,
} from 'graphql-queries/FundingSources.js';
import useStdQuery from 'components/data/hooks/useStdQuery.js';
import PublicAppVars from 'utils/PublicAppVars.js';

// We can only do a "soft" delete, payment methods just get assigned a deleted status.
// Filter out deleted cards.
export const filterDeleted = fundingSources => fundingSources?.filter(wsFundingSourceExt => wsFundingSourceExt.status !== "Deleted");

export const useFundingSources = () => {
	const response = useStdQuery(FUNDING_SOURCES_GET_QUERY);

	const {
		fundingSources,
		fundingSourceCountRemaining,
		creditCardFundingSourceCountRemaining,
		directDebitFundingSourceCountRemaining,
		directDebitFundingSourceAttemptsCountRemaining,
	} = response?.data?.session ?? {};

	const wsFundingSourceInfos = filterDeleted(fundingSources);

	return {
		wsFundingSourceInfos,
		fundingSourceCountRemaining,
		creditCardFundingSourceCountRemaining,
		directDebitFundingSourceCountRemaining,
		directDebitFundingSourceAttemptsCountRemaining,

		...response,
	};
};

const convertMMYY_toDateTime = cardExpiryMMYY => {
	const month = `${cardExpiryMMYY[ 0 ]}${cardExpiryMMYY[ 1 ]}`;
	const year =`20${cardExpiryMMYY[ 2 ]}${cardExpiryMMYY[ 3 ]}`;

	return new Date(`${year}-${month}-1`);
};

// Returns funding Sources that are expired or will be expired
export const useExpiringFundingSources = () => {
	const { wsFundingSourceInfos } = useFundingSources(); 	// Return all active fundingSources
	const today = useMemo(() => new Date(),[]);

	const expiredFundingSources = wsFundingSourceInfos?.filter(source => {
		if (source.creditCard?.cardExpiryMMYY) {
			const convertedDate = convertMMYY_toDateTime(source.creditCard?.cardExpiryMMYY);

			return today.getTime() > convertedDate.getTime();
		}
	}) ?? [];

	const expiringFundingSources = wsFundingSourceInfos?.filter(source => {
		if (source.creditCard?.cardExpiryMMYY) {
			const convertedDate = convertMMYY_toDateTime(source.creditCard?.cardExpiryMMYY);

			// Card is already expired
			if (today.getTime() > convertedDate.getTime()) {
				return false;
			}

			const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
			const daysTilExpired = Math.round(Math.abs((convertedDate - today) / oneDay));

			// We only want the funding sources between the window of 0 days and Nth days.
			return daysTilExpired > 0 && daysTilExpired <= PublicAppVars.EXPIRING_FUNDING_SOURCE_DAY_COUNT;
		}
	}) ?? [];

	return {
		expiredFundingSources,
		expiringFundingSources,
	};
};
