import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import cx from 'classnames';
import {
	values,
	sortBy,
	groupBy,
} from 'lodash';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';

import {
	CARD_STATUS_ACTIVE,
	CARD_STATUS_SUSPENDED,
	getCardType,
} from 'utils/Cards.js';
import { PassTimeStamp } from 'components/account/ProductItem.js';

import { colorCodeStatus } from 'utils/Styles.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import TokenExpiryDate from 'components/account/card/TokenExpiryDate.js';
import TokenNameAndPan from 'components/account/card/TokenNameAndPan.js';
import { Warning } from 'components/Icon.js';
import { CardImage } from 'components/icons/CardBrands.js';
import { usePurseBalanceContext } from 'context/PurseBalanceContext.js';

import * as cardStyles from 'components/account/Card.module.css';
import * as typography from "styles/typography.module.css";
import { RIDER_CLASSES } from 'components/data/transit-account/TransitAccount';
import { isGhostCard } from 'components/manage-cards/TokenHelpers';
import { useCanViewBalance, useIsEmvCard } from "../data/transit-account/EMV.helpers.js";

const cms = {
	balance: 'miscText.cardinfo-balance',
	status: 'miscText.cardinfo-status',
	activates: 'miscText.cardinfo-actives',
	expires: 'miscText.cardinfo-expiry',
	riderClass: 'miscText.cardinfo-fare-type',
	ghostCardLabel: "miscText.empty-account-nickname",
};

const Card = ({
	wsSubsystemAccountInfo,
	wsSubsystemAccountToken,
	subsystemAccountReference,
}) => {
	const { riderClassId, riderClassDescription, subsystemAccountDetailedInfo } = wsSubsystemAccountInfo;

	const isEmv = useIsEmvCard({ subsystemAccountReference });
	const canViewBalance = useCanViewBalance({ subsystemAccountReference });

	const {
		purseTotal,
		hasNegativeBalance,
		products,
	} = usePurseBalanceContext();

	const groupedProducts = groupBy(products, 'passSKU');

	// ghost cards dont have a primary token
	// https://reflexions.atlassian.net/browse/MBTA-2758
	const ghostCard = isGhostCard(subsystemAccountDetailedInfo);

	const mediaType = ghostCard ? null : wsSubsystemAccountToken?.mediaType;

	const tokenInfo = ghostCard
		? null
		: wsSubsystemAccountToken.tokenInfo;

	const frontendStatus = ghostCard
		? null
		: wsSubsystemAccountToken.tokenInfo?.frontendStatus;

	const mobileWalletType = ghostCard
		? null
		: wsSubsystemAccountToken.tokenInfo?.mobileWalletType;

	return (
		<CmsContentList list={values(cms)}>{() =>
			<Link
				key={subsystemAccountReference + wsSubsystemAccountToken?.tokenId}
				to={getPathByRoute(routeKeys.AccountCardOverview, {
					transit_account_id: subsystemAccountReference,
				})}
				className={cx(cardStyles.card, colorCodeStatus(wsSubsystemAccountToken?.status, cardStyles))}
			>
				<h2 className={cx(typography.h7, cardStyles.cardTitle)}>
					{ghostCard
						? <CmsContentRenderer.Span
							className={cardStyles.cardStatLabel}
							contentKey={cms.ghostCardLabel}
							fallbackValue='History'
						/>
						: <TokenNameAndPan {...{ tokenInfo, mediaType: wsSubsystemAccountToken.mediaType }} />}
					{!ghostCard && wsSubsystemAccountToken.status === CARD_STATUS_SUSPENDED
						? <Warning overrideClass={cardStyles.cardTitleAlert} aria-hidden={true} />
						: null}
				</h2>

				<div className={cardStyles.cardContent}>
					<div className={cardStyles.cardMedia}>
						{/* // ghost cards dont have a primary token
						    // https://reflexions.atlassian.net/browse/MBTA-2758 */}
						<CardImage
							mobileWalletType={mobileWalletType}
							className={cardStyles.icon}
							status={wsSubsystemAccountToken?.frontendStatus}
							creditCardType={ghostCard ? null : getCardType({ tokenInfo, isMonochrome: frontendStatus === CARD_STATUS_SUSPENDED, mediaType })} />
					</div>

					<div className={cardStyles.cardInfo}>
						{canViewBalance && <div className={cardStyles.cardStat}>
							<CmsContentRenderer.Span
								className={cardStyles.cardStatLabel}
								contentKey={cms.balance}
								fallbackValue='Balance'
							/>
							<span className={cx(cardStyles.cardStatValue, cardStyles.isLarge, hasNegativeBalance && cardStyles.isSuspended)}>
								{centsToDisplay(purseTotal)}
							</span>
						</div> }

						{ghostCard
							? null
							: wsSubsystemAccountToken.frontendStatus !== CARD_STATUS_ACTIVE &&
							<div className={cardStyles.cardStat}>
								<CmsContentRenderer.Span
									className={cardStyles.cardStatLabel}
									contentKey={cms.status}
									fallbackValue='Status'
								/>
								<span className={cx(cardStyles.cardStatValue, colorCodeStatus(wsSubsystemAccountToken.frontendStatus))}>
									{wsSubsystemAccountToken.frontendStatus}
								</span>
							</div>}

						<div className={cardStyles.cardStat}>
							{ghostCard
								? null
								: (tokenInfo.status === 'pending')
									? <CmsContentRenderer.Span
										className={cardStyles.cardStatLabel}
										contentKey={cms.activates}
										fallbackValue='Activates On'
									/>
									: <CmsContentRenderer.Span
										className={cardStyles.cardStatLabel}
										contentKey={cms.expires}
										fallbackValue='Expires'
									/>}
							{ghostCard
								? null
								: <span className={cardStyles.cardStatValue}>
									<TokenExpiryDate {...{ isEmv, tokenInfo }} />
								</span>}
						</div>

						{ghostCard
							? null
							// Only display rider class if it is NOT full fare
							: riderClassId !== RIDER_CLASSES.fullFare &&
							<div className={cardStyles.cardStat}>
								<CmsContentRenderer.Span
									className={cardStyles.cardStatLabel}
									contentKey={cms.riderClass}
									fallbackValue='Reduced fare'
								/>

								<span className={cardStyles.cardStatValue}>
									{riderClassDescription}
								</span>
							</div>
						}

					</div>
				</div>

				{Object.keys(groupedProducts).map(productKey => {
					const passGroup = sortBy(groupedProducts[ productKey ], [ 'startDateTime', 'endDateTime', 'createdDateTime' ]);
					const product = passGroup[ 0 ];

					return (
						<div key={product.passSKU}>
							<div className={cardStyles.cardProduct}>
								<p className={cardStyles.cardProductTitle}>{product.passDescription}{passGroup.length > 1 ? (" x" + (passGroup.length)) : ""}</p>
								<div className={cardStyles.cardProductText}>
									<PassTimeStamp {...{ wsSubsystemAccountPass: product }} />
								</div>
							</div>
						</div>
					);
				})}
			</Link>
		}</CmsContentList>
	);
};

Card.propTypes = {
	subsystemAccountReference: PropTypes.string.isRequired,
	wsSubsystemAccountToken: PropTypes.object.isRequired,
	balance: PropTypes.string,
};

export default Card;
