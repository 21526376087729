import React, { useRef, useState } from 'react';
import { filter, forEach, isEmpty, map, merge, values, reduce, compact } from 'lodash';
import * as style from './TransferProducts.module.css';
import * as checkboxStyle from 'components/forms/Checkbox.module.css';

import {
	object as yup_object,
	number as yup_number,
	array as yup_array,
	string as yup_string,
	boolean as yup_boolean,
} from 'yup';
import cx from "classnames";

import Button from 'components/Button.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Checkbox, { BoolCheckbox } from 'components/forms/Checkbox.js';
import TakeOverLayout from 'layouts/TakeOverLayout.js';
import CmsContentList from 'components/data/CmsContentList.js';
import useStdQuery from 'components/data/hooks/useStdQuery.js';
import { USER_TRAVEL_CARDS_QUERY } from 'components/data/session-user/SessionUser.js';
import FormHelper from 'utils/FormHelper.js';
import PreventDefault from 'utils/PreventDefault.js';
import Input from 'components/forms/Input.js';
import { getSupportsTransferPurses, usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';
import { castNumberStringToNumber, RangeError } from 'components/account/reload-balance/helpers.js';

import { PAYMENT_TYPES } from 'server/api-types/WSPayment.js';
import { Lifecycles } from 'libreact/lib/Lifecycles';
import { findPrimaryToken } from 'components/manage-cards/TokenHelpers.js';
import { TOKEN_ENABLEMENT_FEE_STATUS } from 'server/api-types/WSTravelTokenEnablementFeeInfo.js';
import { isPrimaryTokenNegativeSuspended } from 'utils/transit-account-utils/StatusUtils.js';
import CmsContentRenderedInline from "../../../components/data/CmsContentRenderedInline.js";
import { levels, noticeError } from 'utils/Logger.js';
import Tooltip from "components/Tooltip.js";
import * as tooltipStyle from "components/Tooltip.module.css";
import { useStepContext } from 'context/StepContext.js';
import { TRANSFER_FLOW_STEPS } from './TransfersFlow.js';
import { useTransferContext } from 'context/TransferContext.js';
import LoadingIcon, { SIZES } from 'components/icons/LoadingIcon.js';
import { DELINK_FLOW_STEPS } from '../DeLinkCardFlow.js';
import { GET_MANAGED_CUSTOMER_SUBSYSTEM_ACCOUNTS } from "components/data/alternates/Alternates.query.js";
import TransferTargetCardSelector from "components/account/card/TransferTargetCardSelector.js";
import { GET_ALTERNATE_ACCOUNT_FROM_SESSION } from "components/data/alternates/SessionAlternate.js";
import { COLLECTIONS, getErrorKey } from 'utils/GetErrorKey.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import { CARD_TYPE_CONTACTLESS } from 'utils/Constants.js';

const cms = {
	headerDelink: 'miscText.remove-transit-header',
	header: 'miscText.transfer-header',
	description: 'miscText.transfer-description-standard',
	descriptionDelink: 'miscText.transfer-description-delinking',
	descriptionTooltip: 'miscHtml.transfer-description-tooltip',

	balanceAndPasses: 'miscText.transfer-balance-passes',
	balanceAndPassesDescription: 'miscText.transfer-balance-refundable', // needs new content
	optionBalance: 'miscText.transfer-balance-option',

	connectedServices: 'miscText.transfer-connected-services',
	groupMembers: 'miscText.transfer-group',

	cancel: 'miscText.transfer-cancel',
	skip: 'miscText.transfer-skip',
	submit: 'miscText.transfer-submit',
	confirmation: 'miscText.transfer-confirmation',
};

export const getYupSchemaTransfers = (sourceBalance, isMinimumBalanceAmount) => {
	const validations = {
		targetSubsystemAccountReference: yup_string().required('miscHtml.transfer-submit-error-notarget'),
		passSerialNumbers: yup_array().when('transferValueOption', {
			is: (value) => Boolean(value) === false, // if they have not selected a balance transfer
			then: yup_array()
				.of(yup_string().required('miscHtml.transfer-submit-error-noselection')) // then they have to have a pass transfer
				.min(1, 'miscHtml.transfer-submit-error-noselection')
				.required('miscHtml.transfer-submit-error-noselection'),
			otherwise: yup_array().nullable(),  // they are transferring balance, and it doesn't matter if there are passes or not
		}),
		transferValueOption: yup_boolean(),
	};

	// If the balance does not meet the minimum then we don't validate the amount.
	// We also do not display the balance field to allow the user to enter an amount.
	if (isMinimumBalanceAmount) {
		validations.transferValue = yup_number().when('transferValueOption', {
			is: (value) => Boolean(value),
			then: yup_number()
				.min(PublicAppVars.TRANSFER_MIN_AMOUNT, () => <RangeError min={PublicAppVars.TRANSFER_MIN_AMOUNT}
					max={sourceBalance} />)
				.max(sourceBalance, () => <RangeError min={PublicAppVars.TRANSFER_MIN_AMOUNT} max={sourceBalance} />)
				.required(getErrorKey(COLLECTIONS.purchaseStoredValue, 'loadProductLineItems[n].product', 'errors.general.value.required')),
			otherwise: yup_number().nullable(),
		});
	}

	return yup_object().shape(validations);
};

const filterTargetCards = ({ subsystemAccounts, sourceSubsystemAccountReference }) => filter(subsystemAccounts, ({
	subsystemAccountReference: targetSubsystemAccountReference,
	subsystemAccountDetailedInfo,
}) => {
	const primaryToken = findPrimaryToken(subsystemAccountDetailedInfo?.tokens || []) ?? null;

	const enablementStatus = primaryToken?.tokenInfo?.tokenEnablementFee?.status ?? null;
	const isEmv = primaryToken?.subsystemTokenType === CARD_TYPE_CONTACTLESS;

	return (!isEmv
		&& (sourceSubsystemAccountReference !== targetSubsystemAccountReference) // this card is not the source card
		// Target card must be active, or if it's suspended it must be due to negative balance. https://reflexions.slack.com/archives/CCF68M49M/p1639164662485500?thread_ts=1639162396.481800&cid=CCF68M49M
		// Note that a card with a negative balance and an active pass would not be "Suspended". Regardless, it would still be eligible for transfer.
		&& isPrimaryTokenNegativeSuspended(subsystemAccountDetailedInfo)
		// Target card's enablement fee cannot be unpaid
		&& (enablementStatus !== TOKEN_ENABLEMENT_FEE_STATUS.UNPAID)
		// Target card must have at least a purse that supports transfer OR a pass that supports transfer to allow any transfer.
		&& (
			// Check target card has at least one PURSE that supports transfer https://reflexions.slack.com/archives/CCF68M49M/p1639168660488500?thread_ts=1639162396.481800&cid=CCF68M49M
			!isEmpty(getSupportsTransferPurses(subsystemAccountDetailedInfo.purses))
			// Check target card has at least one PASS that supports transfer https://reflexions.slack.com/archives/CCF68M49M/p1639168660488500?thread_ts=1639162396.481800&cid=CCF68M49M
			|| !isEmpty(filter(subsystemAccountDetailedInfo.passes, ({ supportsTransfer }) => supportsTransfer))
		)
	);
});

const getEligibleTargetCards = ({
	sourceSubsystemAccountReference,
	subsystemAccounts,
	managedCustomers,
}) => {
	const eligibleTargetCards = map(filterTargetCards({ subsystemAccounts, sourceSubsystemAccountReference }),
		(wsSubsystemAccountInfo) => ({
			wsSubsystemAccountInfo,
			managedCustomerLinkInfo: null,
		}),
	);

	const managedCustomerTargetCards = reduce(managedCustomers, (accumulator, {
		managedCustomerDetails,
		managedCustomerLinkInfo,
	}) => {
		const filteredSubsystemAccounts = filterTargetCards({
			subsystemAccounts: managedCustomerDetails.subsystemAccounts,
			sourceSubsystemAccountReference,
		});

		if (isEmpty(filteredSubsystemAccounts)) {
			return accumulator;
		}

		return [
			...accumulator,
			...map(filteredSubsystemAccounts, (wsSubsystemAccountInfo) => ({
				wsSubsystemAccountInfo,
				managedCustomerLinkInfo,
			})),
		];
	}, []);

	return [
		...eligibleTargetCards,
		...managedCustomerTargetCards,
	];
};

const TransferProducts = ({
	transitAccountQ,
	cancelUrl,
	isDelink = false,
	takeoverTitle,
	setHideTransfer = null,
}) => {
	// DATA FROM CONTEXTS
	const { step, nextStep } = useStepContext();
	const {
		cart,
		addValue,
		addPass,
		removePass,
		setTargetCard,
		setTransferValueBool,
		validationState,
		setValidationState,
		resetTransfer,
	} = useTransferContext();

	const { supportsTransferTotal: sourceBalance } = usePurseBalanceContext();

	// Only show the balance portion if the value is greater than or equal to the minimum supplied
	const isMinimumBalanceAmount = sourceBalance >= PublicAppVars.TRANSFER_MIN_AMOUNT;

	// SET UP PAGE DATA
	const { subsystemAccountReference, passes, riderClass: sourceRiderClass } = transitAccountQ;

	// Fetches all cards on the account to populate target card dropdown
	const {
		data: userTravelCardsQueryData,
		loading: userTravelCardsQueryLoading,
	} = useStdQuery(USER_TRAVEL_CARDS_QUERY);

	const {
		data: alternateSessionQueryData,
		loading: alternateSessionQueryLoading,
	} = useStdQuery(GET_ALTERNATE_ACCOUNT_FROM_SESSION);

	const {
		data: managedCustomerTravelCardsQueryData,
		loading: managedCustomerTravelCardsQueryLoading,
	} = useStdQuery(GET_MANAGED_CUSTOMER_SUBSYSTEM_ACCOUNTS, {
		skip: alternateSessionQueryLoading || alternateSessionQueryData.session.alternateSession,
	});

	const [ submitting, setSubmitting ] = useState(false);

	const formRef = useRef(null);
	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));

	if (userTravelCardsQueryLoading || alternateSessionQueryLoading || managedCustomerTravelCardsQueryLoading) {
		return (<LoadingIcon size={SIZES.component} />);
	}

	const formHelper = formHelperRef.current;

	formHelper.onHookedRender(
		validationState,
		setValidationState,
		() => getYupSchemaTransfers(sourceBalance, isMinimumBalanceAmount),
		() => {
			// convert currency input strings as numbers
			const data = formHelper.getFormData();
			const elements = formHelper.getFormElements();

			forEach(elements, element => {
				if (element.name === 'transferValue') {
					const fieldName = element.name;

					//convert decimal amount to int
					data[ fieldName ] = parseInt(element.value.replace(/[^0-9.]+/g, "") * 100);
				}
				if (element.name === 'passSerialNumbers') {
					const fieldName = element.name;
					data[ fieldName ] = Boolean(element.value) ? element.value.split(',') : [];
				}
			});
			return data;
		},
	);

	const { subsystemAccountInfoQ } = userTravelCardsQueryData;
	const managedCustomers = managedCustomerTravelCardsQueryData?.CustomerRoute?.alternates.managedCustomers ?? [];

	// TARGET CARD LOGIC
	// Get eligible target cards from call
	const eligibleTargetCards = getEligibleTargetCards({
		sourceSubsystemAccountReference: subsystemAccountReference,
		subsystemAccounts: subsystemAccountInfoQ,
		managedCustomers,
	});

	const targetRiderClass = eligibleTargetCards
		? eligibleTargetCards?.find(card =>
			card.wsSubsystemAccountInfo.subsystemAccountReference === cart.targetCard?.subsystemAccountReference)?.wsSubsystemAccountInfo.riderClassId
		: null;

	// TRANSFER BALANCE LOGIC
	const onChangeCustomAmount = (value) => {
		// If we have a value in the input then check the checkbox
		if (value && !cart.transferValueOption) {
			setTransferValueBool(true);
		}
		// If we have no value in the input then uncheck the checkbox
		else if (!value && cart.transferValueOption) {
			setTransferValueBool(false);
		}

		addValue(castNumberStringToNumber(value));
	};

	const onChangeBalanceCheckbox = (checkedValue) => {
		// Load up the full amount when the check box is select to be true
		if (checkedValue && !cart.transferValue) {
			addValue(castNumberStringToNumber(sourceBalance));
		}
		// Remove the amount if false
		else if (!checkedValue) {
			addValue(0, true);
		}

		setTransferValueBool(checkedValue);
	};

	// SOURCE PASS LOGIC
	// Find eligible passes from source
	const allEligiblePasses = filter(
		compact(passes),
		({
			supportsTransfer,
			passUseCount,
			autoloadEnabled,
			payments,
		}) => (
			supportsTransfer
			&& (passUseCount === 0) // Cannot Transfer a used Pass
			&& (!autoloadEnabled) // Cannot Transfer a currently enrolled in autoload
			&& (payments?.paymentType !== PAYMENT_TYPES.ppb) // cannot transfer a card that was purchased pre-tax
		),
	); // unclear if this payment type is the correct one. Also looks like payments do not come from BO?
	// @todo confirm above conditions with Jon

	const eligiblePasses = allEligiblePasses; // uniqBy(allEligiblePasses, 'passSKU'); // Enabling this code will show only one of each type of pass

	const handlePassSelection = ({ target }, wsSubsystemAccountPass) => {
		const { checked } = target;

		if (checked) {
			addPass(wsSubsystemAccountPass);
		} else {
			removePass(wsSubsystemAccountPass);
		}
	};

	// FORM LOGIC
	const handleSubmit = async () => {
		setSubmitting(true);

		try {
			await formHelper.startValidation(true);
		} catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
			noticeError(null, levels.info, errorReport, `Transfer Product Validation failed`);

			setSubmitting(false);
			return;
		}

		nextStep();
	};

	const skipTransfer = () => {
		resetTransfer();
		nextStep();
	};

	// if user is delinking and the card has no passes or value to transfer OR there are no target cards, skip to the delink card step.
	const noItemsForTransfer = isEmpty(eligiblePasses) && (sourceBalance === 0);
	const noTargetCards = isEmpty(eligibleTargetCards);
	if (isDelink && (noItemsForTransfer || noTargetCards)) {
		setHideTransfer(true);
		nextStep();
	}

	return (
		<Lifecycles didMount={formHelper.wireInputs}>
			<CmsContentList list={values(cms)}>{({ cmsContent }) => (
				<form
					method="post"
					data-qa="transferProductForm"
					ref={formRef}
					onSubmit={PreventDefault(handleSubmit)}
				>
					<TakeOverLayout
						title={cmsContent[ takeoverTitle ]}
						cancelLink={cancelUrl}
						showCancel
						showNickname
						steps={isDelink ? DELINK_FLOW_STEPS : TRANSFER_FLOW_STEPS}
						currentStep={step}
					>
						<div className={style.wrapper}>
							<div className={style.section}>
								<CmsContentRenderer.Span
									contentKey={isDelink ? cms.descriptionDelink : cms.description} />
								<Tooltip
									tooltipId={'transferProductFormToolTip'}
									overrideClass={tooltipStyle.inlineWithText}
									ariaLabelPanel={cmsContent[ cms.descriptionTooltip ] || 'Groups and connected services can only be transferred to other cards on this Charlie account. Pre-tax balance or passes must remain on this card. Passes issued by a group must remain on this card'}
								>
									<CmsContentRenderer.Span
										rawHtml={true}
										contentKey={cms.descriptionTooltip}
									/>
								</Tooltip>
							</div>

							<div className={style.section}>
								<TransferTargetCardSelector
									travelCards={eligibleTargetCards}
									{...{
										cart,
										setTargetCard,
										formHelper,
									}}
								/>
							</div>
							<div className={style.section}>
								<CmsContentRenderer.Span className={style.transferSubheader}
									contentKey={cms.balanceAndPasses} fallbackValue="Balance and Passes" />
								{isMinimumBalanceAmount &&
									<>
										<CmsContentRenderer.P contentKey={cms.balanceAndPassesDescription}
											fallbackValue="Refundable balance that is transferred will no longer be eligible for refund at fare vending machines. Pre-tax balance is not eligible for transfer." />
										<div className={style.transferBalance}>
											<BoolCheckbox
												name="transferValueOption"
												onChange={(event) => PreventDefault(onChangeBalanceCheckbox(event.target.checked))}
												checked={cart.transferValueOption}
												label={
													<CmsContentRenderedInline
														className={checkboxStyle.labelText}
														contentKey={cms.optionBalance}
														variables={{ amount: centsToDisplay(sourceBalance) }}
													/>
												}
											/>
										</div>
										<Input
											hideLabel
											name="transferValue"
											overrideClass={style.transferValueInput}
											controlled={true}
											value={cart?.transferValue && centsToDisplay(cart.transferValue)}
											placeholder={centsToDisplay(0)}
											onChange={PreventDefault((event) => onChangeCustomAmount(event.target.value))}
											error={formHelper.getFieldError('transferValue')}
										/>
									</>
								}

								{/* Cannot transfer a pass to a card with a different rider class than the source */}
								{!isEmpty(eligiblePasses) && sourceRiderClass === targetRiderClass
									? map(eligiblePasses, (wsSubsystemAccountPass) => (
										<div className={style.transferProduct} key={wsSubsystemAccountPass.id}>
											<Checkbox
												checked={!isEmpty(filter(cart.transferPasses, pass => pass === wsSubsystemAccountPass))}
												name={`SN-${wsSubsystemAccountPass?.passSerialNbr}`}
												label={`${wsSubsystemAccountPass.passDescription}`}
												onChange={(event) => handlePassSelection(event, wsSubsystemAccountPass)}
											/>
										</div>
									))
									: null
								}
							</div>
							{formHelper.getFieldErrorJsx('')}

							<Input
								type="hidden"
								name="passSerialNumbers"
								hideLabel
								value={merge(cart?.transferPasses, ({ passSerialNbr }) => passSerialNbr)}
								controlled={true}
								error={formHelper.getFieldErrorJsx('passSerialNumbers')}
							/>

							<div className={cx(style.section, style.transferButtonsWrapper)}>
								{isDelink
									? <Button
										additionalClassNames={style.transferButton}
										onClick={PreventDefault(skipTransfer)}
										isPrimary={false}>
										<CmsContentRenderer.Span contentKey={cms.skip} fallbackValue="Skip this step" />
									</Button>
									: null}
								<Button
									additionalClassNames={style.transferButton}
									isPrimary
									type="submit"
									submitting={submitting}>
									<CmsContentRenderer.Span contentKey={cms.submit} fallbackValue="Next" />
								</Button>
							</div>
						</div>
					</TakeOverLayout>
				</form>
			)}</CmsContentList>
		</Lifecycles>);
};

export default TransferProducts;
