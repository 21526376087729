import React from "react";
import PropTypes from 'prop-types';
import { getCardExpiryDate } from "components/card-icons/util";
import { shortYear } from "utils/FormatHelpers";
import ServerDate from "components/ServerDate";


export const TokenExpiryDate = ({
	tokenInfo,
	elementType: ElementType = "span",
	isEmv = false,
	...rest
}) => {
	//https://reflexions.atlassian.net/issues/MBTA-3134
	// Format to MM/YY
	const expirationDateISO = getCardExpiryDate(tokenInfo);
	const expirationDate = new Date(expirationDateISO);

	const month = expirationDate.getMonth() + 1;

	const formattedMonth = month < 10
		? `0${month}`
		: month;
	const year = shortYear(expirationDate.getFullYear());

	return (
		<ElementType {...rest}>
			{isEmv
				? `${formattedMonth}/${year}`
				: <ServerDate
					dateISO={expirationDateISO}
					options={{ month: 'short', day: "numeric", year: 'numeric' }}
				/>
			}
		</ElementType>
	);
};

TokenExpiryDate.propTypes = {
	elementType: PropTypes.elementType,
	tokenInfo: PropTypes.shape({
		tokenType: PropTypes.string,
		expirationYear: PropTypes.number,
		expirationMonth: PropTypes.number,
		cardExpiryDate: PropTypes.string,
	}).isRequired,
	isEmv: PropTypes.bool,
};

export default TokenExpiryDate;
