import React from 'react';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import CardLayout, { getCardLayoutBreadcrumbs } from 'layouts/CardLayout.js';
import TripHistoryTable from 'components/account/data-tables/TripHistoryTable.js';
import { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";
import TapsAlertsProvider from "context/TapsAlertsContext.js";
import {  useBreadcrumbCallback } from 'context/BreadcrumbProvider';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline';


const cms = {
	title: 'miscText.triphistory-header',
};
export const getTripHistoryBreadcrumbs = () => [
	...getCardLayoutBreadcrumbs(),
	<CmsContentRenderedInline
		key={cms.title}
		contentKey={cms.title}
		fallbackValue="Trip History"
	/>,
];

const TripHistory = () => {
	useBreadcrumbCallback(getTripHistoryBreadcrumbs);

	return (
		<CmsContentList list={Object.values(cms)}>
			{() => (
				<CardLayout {...{
					title: <CmsContentRenderer.Span contentKey={cms.title} fallbackValue="Trip History" />,
					showMissedTaps: true,
				}} >
					<TripHistoryTable />
				</CardLayout>
			)}
		</CmsContentList>
	);
};

export default TripHistory;
