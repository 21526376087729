import React, { useState } from 'react';
import {
	first,
	isEmpty,
} from "lodash";
import cx from 'classnames';

import {
	GET_ALTERNATES_AND_MANAGED_CUSTOMERS,
	PendingManagedCustomers,
} from "components/data/alternates/Alternates.query.js";
import CmsContentList from "components/data/CmsContentList.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import useStandardMutation from "components/data/hooks/useStandardMutation.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";

import Banner, { TYPE_INFO } from "components/Banner.js";
import Button, {
	Secondary,
	buttonTypeStylePlain,
} from "components/Button.js";

import * as bannerStyle from 'components/Banner.module.css';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import { gql } from "@apollo/client";
import FormHelper from "utils/FormHelper.js";
import { LINK_STATUSES } from "server/api-types/WSAlternateContactInfo.js";
import LoadingIcon, { SIZES } from "components/icons/LoadingIcon.js";



const cms = {
	header: 'miscText["alternates-invite-banner.header"]',
	body: 'miscText["alternates-invite-banner.description"]',
	learnMoreText: 'miscText["alternates-invite-banner.learnmore"]',
	learnMoreLink: 'miscText["alternates-invite-banner.learnmore-url"]',
	accept: 'miscText["alternates-invite-banner.accept"]',
	decline: 'miscText["alternates-invite-banner.decline"]',
};

const ACTION_ACCEPT = 'ACCEPT';
const ACTION_DECLINE = 'DECLINE';

const ACTION_INVITE = gql`
	mutation actionInvite(
		$action: String!
		$linkId: ID!
	) {
		CustomerMutationRoute {
			alternates {
				alternateAction(
					action: $action
					linkId: $linkId
				)
			}
		}
	}
`;

const AlternatePendingInvite = () => {
	const [ actionMutation, { loading } ] = useStandardMutation(ACTION_INVITE);
	const [ errorMessage, setErrorMessage ] = useState(null);

	const kickoffSubmit = async (linkId, action) => {
		try {
			await graphqlErrorMiddleware(actionMutation({
				variables: {
					action,
					linkId,
				},
				awaitRefetchQueries: true,
				refetchQueries: [
					{ query: GET_ALTERNATES_AND_MANAGED_CUSTOMERS },
					{ query: GET_ALTERNATES_AND_MANAGED_CUSTOMERS, variables: { linkStatuses: [ LINK_STATUSES.pending ] } },
					{ query: GET_ALTERNATES_AND_MANAGED_CUSTOMERS, variables: { linkStatuses: [ LINK_STATUSES.accepted ] } },
				],
			}));
		} catch (error) {
			setErrorMessage(error.display.topLevelMessage);
		}
	};

	return (
		<PendingManagedCustomers>
			{({ pendingWsManagedCustomerInfos }) => {

				if (isEmpty(pendingWsManagedCustomerInfos)) {
					return null;
				}

				const { firstName, linkId } = first(pendingWsManagedCustomerInfos);

				return (
					<CmsContentList list={Object.values(cms)}>{({ cmsContent }) => (
						<Banner
							type={TYPE_INFO}
							title={<CmsContentRenderedInline
								contentKey={cms.header}
								fallbackValue={`${firstName} has invited you to manage their account.`}
								variables={{ name: firstName }}
							/>}
							body={<>
								<CmsContentRenderer.P
									contentKey={cms.body}
									fallbackValue="You have been invited to manage another registered user's account. You will have limited access to their data."
								/>
								{errorMessage && FormHelper.errorJsx(errorMessage)}
							</>}
							link={
								<Button
									typeStyle={buttonTypeStylePlain}
									external
									to={cmsContent[ cms.learnMoreLink ]}
								>
									<CmsContentRenderer.Span
										contentKey={cms.learnMoreText}
										fallbackValue="Learn more about managing other's accounts"
									/>
								</Button>
							}
							rightSide={
								loading
									? <LoadingIcon size={SIZES.component} />
								    : <div className={bannerStyle.actions}>
										<Button
											key={'acceptInvite'}
											isPrimary
											onClick={() => kickoffSubmit(linkId, ACTION_ACCEPT)}
											additionalClassNames={cx(bannerStyle.button, bannerStyle.action)}
										>
											<CmsContentRenderer.Span contentKey={cms.accept} fallbackValue="Accept" />
										</Button>

										<Button
											key={'declineInvite'}
											type={Secondary}
											onClick={() => kickoffSubmit(linkId, ACTION_DECLINE)}
											additionalClassNames={cx(bannerStyle.button, bannerStyle.action)}
										>
											<CmsContentRenderer.Span contentKey={cms.decline} fallbackValue="Decline" />
										</Button>
									</div>
							}
						/>
					)}</CmsContentList>
				);
			}}
		</PendingManagedCustomers>
	);
};

export default AlternatePendingInvite;
