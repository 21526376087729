import React from 'react';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Banner, { TYPE_ERROR } from 'components/Banner.js';
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';

import Button, { buttonTypeStylePlain } from 'components/Button.js';
import * as style from './RegisterForAccount.module.css';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline';


const cms = {
	title: 'miscText["card-overview-banner-charlie-expired.title"]',
	description: 'miscText["card-overview-banner-charlie-expired.description"]',
	link: 'miscText["card-overview-banner-charlie-expired.button"]',
};


const ExpiredClosedLoop = ({ transit_account_id }) => {

	return <CmsContentList list={Object.values(cms)}>{() => (
		<Banner
			type={TYPE_ERROR}
			title={<CmsContentRenderedInline
				contentKey={cms.title}
				fallbackValue={`Charlie Card has Expired`}

			/>}
			body={<CmsContentRenderer.P
				contentKey={cms.description}
				fallbackValue="This Charlie Card has expired"
			/>}
			link={
				<Button
					to={getPathByRoute(routeKeys.BlockOrReplaceFlow, { transit_account_id })}
					typeStyle={buttonTypeStylePlain}
					additionalClassNames={style.registerButtonOverride}
				>
					<CmsContentRenderer.Span contentKey={cms.link} fallbackValue="Order Replacement" />
				</Button>
			}
		/>
	)}</CmsContentList>;
};

export default ExpiredClosedLoop;
